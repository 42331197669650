<template>
  <div :class="{ 'result-item result-expanded mb-3': true, 'expanded': true}" :style="cssProps" :id="'vehicle-'+item.selectedVehicle.id">
  
      <div class="first-row row m-0 w-100">
        <div class="col-12 d-flex flex-row align-items-center">
          <div class="col-image">
            <img  :src="item.selectedVehicle.image || item.selectedVehicle.thumbnail" class="vehicle-image">
          </div>
          <div class="col-info">
            <h3 class="title">{{ item.selectedVehicle.title || item.selectedVehicle.name }}</h3>
            <div class="configurations-wrapper" v-if="(item.selectedVehicle.configurations.length > 0 || item.selectedVehicle.air_conditioning || item.selectedVehicle.bathroom)">
              <p class="font-weight-bold">Configurações:</p>
              <p class="configurations">
                <span v-if="item.selectedVehicle.air_conditioning">
                  Ar condicionado
                </span>
                <span v-if="item.selectedVehicle.bathroom">
                  Banheiro
                </span>
                
                <span v-for="(config, index) in item.selectedVehicle.configurations" :key="index">
                  {{config}}
                </span>
              </p>
            </div>
          </div>

          <div class="col-price text-right">
          <div class="price w-100">
            <span class="value">{{ ( item.total) | currency }}</span>
          </div>

          <button @click.prevent="removeCartItem" class="btn btn-block remove mt-2">REMOVER ITEM</button>
        </div>
        
      </div>
      </div>

      <div class="row row-trip-info w-100">
        <div class="col-12 mt-4">
          <h4 class="trip-info-title">Detalhes da diária</h4>
          <div class="usage-item align-items-start xl-row mb-4 w-100">

            <div class="col-origin col-md-5 m-0 pr-5">
              <h4 class="trip-info-title">Origem</h4>
              <div class="trip-info">
                <b-icon-geo-alt></b-icon-geo-alt>
                {{ item.daily.addr_info.address }}
              </div>
              <div class="trip-info">
                <b-icon-calendar3></b-icon-calendar3>
                {{ item.daily.date | date_result_list }}
              </div>
              <div class="trip-info">
                <b-icon-clock></b-icon-clock>
                {{ item.daily.time }}
              </div>
            </div>

            <div class="col-destination col-md-5 p-0">
              <h4 class="trip-info-title">Detalhes da diária</h4>
              <div class="trip-info">
                <img class="mr-3" :src="this.busIcon" />
                {{ `${item.daily.quote.daily_hours} horas e ${parseInt(item.daily.quote.franchise_km)} km` }}
              </div>
            </div>

            <div class="col-2 align-items-end">
              <div class="price-wrapper">
                <div class="price">
                  <span class="value">{{ item.total | currency}}</span>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>

      <div class="w-100" v-if="item.observations">
        <div class="row row-trip-info">
          <div class="col-md-12">
            <h4 class="trip-info-title">Observações adicionais</h4>
            <div class="usage-item align-items-start row mb-4 w-100">
              {{ item.observations }}
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>

export default {
  name: 'DailyItem',
  props: ['item', 'index'],
  data () {
    return {
      isVisible: false,
      visible: false,
      gallery: [],
      img: '',
      indexImg: 0,
      busIcon: require('../../assets/icon/bus.svg')
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
    secondaryColor () {
      return this.$store.state.companyDetails.whitelabel.secondaryColor
    }
  },
  methods: {
    removeCartItem () {
      this.$store.commit('cart/removeItem', this.index)
      this.$store.commit("cart/calculateCartTotal", {data: this.$store.state});
    },
    showVehicleDetails () {
      this.isVisible = true
    },
    hideVehicleDetails () {
      this.isVisible = false
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    },
    showMultiple (index) {
      this.img = this.gallery

      this.indexImg = index
      this.show()
    },
    setImgGalery () {
      this.item.selectedVehicle.gallery.forEach(img => {
        this.gallery.push(img.src)
      })
    }
  },
  beforeMount () {
    this.setImgGalery()
  },
  mounted () {
  }
}
</script>


