import { render, staticRenderFns } from "./TripItem.vue?vue&type=template&id=11ef930f&scoped=true&"
import script from "./TripItem.vue?vue&type=script&lang=js&"
export * from "./TripItem.vue?vue&type=script&lang=js&"
import style0 from "./TripItem.vue?vue&type=style&index=0&id=11ef930f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ef930f",
  null
  
)

export default component.exports