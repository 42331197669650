<template>
  <div class="limiter" id="login">
    <div
      class="container-login100"
      :style="{
        backgroundImage: `url(${require('@/assets/img/bg-login.jpg')})`,
      }"
    >
      <div class="wrap-login100">
        <form class="login100-form validate-form" v-on:submit.prevent="register">
          <span class="login100-form-title p-b-34 p-t-27"> Cadastrar </span>

          <div :class="{'form-group':true, 'has-feedback': errors.name}">
            <div
                class="wrap-input100 validate-input"
                data-validate="Nome é obrigatório"
            >
                <input
                class="input100"
                type="text"
                name="name"
                v-model="user.name"
                placeholder="Nome"
                />
                <span class="focus-input100"
                ><font-awesome-icon icon="user"></font-awesome-icon
                ></span>
            </div>

            <div class="invalid-feedback " v-if="errors.name">
                {{ errors.name[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div
                class="wrap-input100 validate-input"
                data-validate="E-mail é obrigatório"
            >
                <input
                class="input100"
                type="email"
                name="email"
                v-model="user.email"
                placeholder="E-mail"
                />
                <span class="focus-input100"
                ><font-awesome-icon icon="at"></font-awesome-icon
                ></span>
            </div>

            <div class="invalid-feedback " v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.password}">
            <div
                class="wrap-input100 validate-input"
                data-validate="Senha é obrigatório"
            >
                <input
                class="input100"
                type="password"
                v-model="user.password"
                name="pass"
                placeholder="Senha"
                />
                <span class="focus-input100"
                ><font-awesome-icon icon="lock"></font-awesome-icon
                ></span>
            </div>

            <div class="invalid-feedback " v-if="errors.password">
                {{ errors.password[0] }}
            </div>
          </div>

          <div class="login-actions">
            <div class="container-login100-form-btn">
              <button class="login100-form-btn">Cadastrar</button>
            </div>
          </div>

          <div class="register-btn-wrapper">
            <p class="register-text">
              Já é cadastrado?
              <a class="txt2" href="/login"> Entrar </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Register',
  data () {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        status: 1
      }
    }
  },
  computed: {
    ...mapGetters(['errors'])
  },
  mounted () {
    this.$store.commit('setErrors', {})
  },
  methods: {
    ...mapActions('auth', ['sendRegisterRequest']),

    register () {
      this.sendRegisterRequest(this.user).then((response) => {
        if (response !== null && response.status === 200) {
          this.$router.push({ name: 'minha-conta' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#nav {
  margin-bottom: 0 !important;
}

@import "../assets/scss/pages/login.scss";
</style>
