<template>
  <div class="limiter" id="login" :style="cssProps">
    <div
      class="container-login100"
      :style="{
        backgroundImage: `url(${require('@/assets/img/homebg.jpg')})`,
      }"
    >
      <div class="wrap-login100">
        <alert />

        <form class="login100-form validate-form" v-on:submit.prevent="login">
          <!-- <span class="login100-form-logo">
            <i class="zmdi zmdi-landscape"></i>
            </span> -->

          <h4 class="mb-4">Faça login para acessar sua conta</h4>

          <div :class="{'form-group':true, 'has-feedback': errors.email}">
            <div
              class="wrap-input100 validate-input"
              data-validate="Enter username"
            >
              <input
                class="input100"
                type="text"
                name="username"
                placeholder="Username"
                v-model="credentials.email"
              />
              <span class="focus-input100"
                ><font-awesome-icon icon="user"></font-awesome-icon
              ></span>
            </div>

            <div class="invalid-feedback " v-if="errors.email">
              {{ errors.email[0] }}
            </div>
          </div>

          <div :class="{'form-group':true, 'has-feedback': errors.password}">
            <div
              class="wrap-input100 validate-input"
              data-validate="Enter password"
            >
              <input
                class="input100"
                type="password"
                name="pass"
                placeholder="Password"
                v-model="credentials.password"
              />
              <span class="focus-input100"
                ><font-awesome-icon icon="lock"></font-awesome-icon
              ></span>
            </div>

            <div class="invalid-feedback " v-if="errors.password">
              {{ errors.password[0] }}
            </div>
          </div>

          <div class="login-actions">
            <div class="login-btn">
              <button class="login-form-btn">Login</button>
            </div>

            <div class="text-center p-t-90 mt-2">
              <router-link  class="forget-pass" to="/recuperar-senha"><span>Esqueceu sua senha?</span> </router-link>
            </div>
          </div>

          <div class="register-btn-wrapper">
            <p class="register-text">
              Não tem uma conta?
            <router-link to="/cadastrar"><span>Cadastre-se</span> </router-link>

            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Alert from '@/components/general/Alert.vue'

export default {
  name: 'Login',
  data: function () {
    return {
      credentials: {
        email: '',
        password: ''
      },
      error: ''
    }
  },
  components: { Alert },
  computed: {
    ...mapGetters(['errors', 'responseMessage']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  mounted () {
    this.$store.commit('setErrors', {})
    this.$store.commit('setResponseMessage', {})
  },
  methods: {
    ...mapActions('auth', ['sendLoginRequest']),

    login () {
      this.sendLoginRequest(this.credentials).then((response) => {
        if (response !== null && response.status === 200) {
          this.$router.push({ name: 'minha-conta' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#nav {
  margin-bottom: 0 !important;
}

.login-btn {
  width: 100%;
  button {
    width: 100%;
    color: white;
    border-radius: 5px;
    background-color: var(--default-color);
    padding: 10px

  }

}

@import "../assets/scss/pages/login.scss";
</style>
