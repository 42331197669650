<template>
  <div class="card-order-item f-card">
    <div class="card-body row">

    <div class="col-md-3 col-image"  v-if="template !== 'list'">

      <img :src="item.vehicle.thumbnail" class="vehicle-image">
          <h3 class="title">{{item.vehicle.title}}</h3>
          <div class="configurations-wrapper" v-if="(item.vehicle.configurations.length > 0 || item.vehicle.air_conditioning || item.vehicle.bathroom)">
            <p class="font-weight-bold">Configurações:</p>
            <p class="configurations">
              <span v-if="item.vehicle.air_conditioning">
                Ar condicionado
              </span>
              <span v-if="item.vehicle.bathroom">
                Banheiro
              </span>

              <span v-for="(config, index) in item.vehicle.configurations" :key="index">
                {{config}}
              </span>
            </p>
        </div>

        <div class="img-container">
        <div class="col-image"  @click="showMultiple(index)" v-for="(img, index) in gallery" :key="index">
          <img style="cursor:pointer" :src="img" class="img-fluid">
        </div>

        <vue-easy-lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="img"
        :index="indexImg"
        @hide="handleHide"
        >
        </vue-easy-lightbox>
      </div>
    </div>

    <div
      :class="{
        'col-xl-6 col-12' : template !== 'list',
        'col-12' : template === 'list',
        'col-details' : true
        }">
      <div class="col-12">
        <div class="row">
            <div class="col-xl-9 title col-12 p-0">{{formatTitleVehicle(item.vehicle.name)}}</div>
            <div class="col-xl-3 col-12 d-flex justify-content-end p-0" v-if="template === 'list'">
              <select class="select-action border border-secondary rounded px-2 py-1" v-model="actionSelected" @change.prevent="budgetActions()">
                  <option disabled value="">O que deseja fazer?</option>
                  <option>Consultar disponibilidade</option>
                  <option>Remover pesquisa</option>
                  <!-- <option>Baixar orçamento</option> -->
                  <option>Adicionar ao orçamento</option>
                </select>
            </div>
        </div>
      </div>
      <p class="mb-3 type-tag">Transfer {{transfer_state}}</p>
      <div class="row">
          <div class="col-xl-9 col-12 d-flex">
            <div class="row">
              <div class="origin-row address col-12">
                <h5 class="trip-info-title">Origem</h5>

                <div class="trip-info-col">
                  <div class="trip-info text-capitalize">
                    <b-icon-geo-alt></b-icon-geo-alt>
                    {{ item.transfer.point_origin.address }}
                  </div>

                  <div class="trip-info text-capitalize">
                    <b-icon-calendar3></b-icon-calendar3>
                    {{this.transferDate | date_result_list}}
                  </div>
                </div>

                <div class="trip-info-col">
                  <div class="trip-info">
                    <b-icon-clock></b-icon-clock>
                    {{ this.transferHour }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-destination col-md-6 p-0">
              <h4 class="trip-info-title">Destino</h4>
              <div class="trip-info">
                  <b-icon-geo-alt></b-icon-geo-alt>
                {{ item.transfer.point_destination.address }}
              </div>
            </div>
          </div>
          <div v-if="this.quote" class="col-xl-3 col-12 col-price d-flex justify-content-end">
              <span class="price pr-4">{{ transferPrice() | currency }}</span>
          </div>
      </div>
      <div class="col-12 p-0" v-if="!this.quote">
        <div class="col-12 my-4" v-if="item.vehicle.vehicle_details">
          <h4 class="mb-3">{{item.vehicle.vehicle_details.value.length > 1 ? 'Veículos' : 'Veículo'}}</h4>
          <div class="row mb-5">
            <div class="col-md-3 col-12 p-3 bg-white rounded mx-xl-2 border" v-for="(info , i) in this.vehicleInfos" :key="info.type">
              <div>
                <h5 class="trip-info-title">Dados do veículo - {{i + 1}}</h5>
                <div class="trip-info">
                  <p class="m-0">Veículo: {{info.type}}</p>
                </div>
                <div class="trip-info">
                  <p class="m-0">Placa: {{info.plate}}</p>
                </div>
                <div class="trip-info">
                  <p class="m-0">Número de ordem: {{info.order}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 my-4" v-if="item.driver.driver_details">
          <h4 class="mb-3">{{item.vehicle.vehicle_details.value.length > 1 ? 'Motoristas' : 'Motorista'}}</h4>
          <div class="row mb-5">
            <div class="col-md-3 col-12 p-3 bg-white rounded mx-xl-2 border" v-for="(driver , i) in this.driverInfos" :key="driver.name">
              <div>
                <h4 class="trip-info-title">Dados do motorista - {{i + 1}}</h4>
                <div style="width: 100%;">
                  <div style="width: 100%;">
                    <div class="trip-info">
                      <p class="m-0">Nome: {{ driver.name }}</p>
                    </div>
                    <div class="trip-info">
                      <p class="m-0">Telefone: {{ driver.phone }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-3 col-price order-price" v-if="template !== 'list'">
      <span class="price">{{ transferPrice() | currency }}</span>
    </div>
  </div>

    <div class="observations-card col-md-12" v-if="item.transferDetails.observations && template !== 'list'">
      <h5 class="trip-info-title">Observações</h5>

      <p class="mb-3">
        {{ item.transferDetails.observations }}
      </p>
    </div>

    <div class="observations-card col-md-12 p-0" v-else-if="item.type === 'transfer' && item.observations && template !== 'list'">
      <h5 class="trip-info-title">Observações</h5>

      <p class="mb-3">
        {{ item.observations }}
      </p>
    </div>

    <!-- <div class="col-12 quote-action-btns d-flex align-content-center justify-content-end" v-if="searchTitle">
      <div class="buttons col-12 d-flex">
        <div class="btn btn-block btn-details" @click="doSearch">
          <span>{{searchTitle}}</span>
        </div>

        <div class="btn btn-block btn-details btn-delete" @click="removeBudget">
          <span>Remover pesquisa</span>
        </div>

        <div class="btn btn-block btn-details btn-delete" @click="print()">
            <span>Baixar orçamento</span>
          </div>
      </div>
    </div> -->

    <div class="col-12 order-item-actions" v-if="template !== 'list'">
      <!-- <a target="_blank" v-if="this.order.payment.method === 'bank_slip'" :href="this.order.payment_link" class="btn btn-order-item-action"><span><font-awesome-icon class="icon-action" icon="file-invoice-dollar"></font-awesome-icon> VER BOLETO</span></a> -->
      <a href="#" @click.prevent="downloadContract(item)" class="btn btn-outline-primary order-item-action generate-contract mt-3">Baixar contrato</a>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import axios from 'axios'
import FileSaver from 'file-saver'

export default {
  name: 'TripItem',
  props: ['item', 'index', 'template', 'searchTitle', 'removeBudget', 'doSearch', 'quote' , 'vehicle', 'setSelectedVehicle' , 'quotePDF' , 'checkout'],
  data () {
    return {
      isVisible: false,
      gallery: [],
      indexImg: 0,
      visible: false,
      img: '',
      transfer_state: this.getTransferState(),
      transferDate: this.getTransferDate(),
      transferHour: this.getTransferHour(),
      paymentHighestDate: false,
      paymentHighestDateFormated: false,
      bank_slip_installments: [],
      emailDate: false, 
      actualDate: false,
      startDate: false,
      endDate: false,
      differenceDays: 0,
      vehicleInfos: this.vehicleDetails(),
      driverInfos: this.driverDetails(),
      actionSelected: ''
    }
  },
  components: { VueEasyLightbox },
  methods: {
    formatTitleVehicle (title) {
      // return title ? title.split(' ').slice(1).join(' ') : ''
      return title
    },
    budgetActions() {
      switch (this.actionSelected) {
        case 'Consultar disponibilidade':
          this.doSearch()
        break;
        case 'Remover pesquisa':
          this.removeBudget()
        break;
        case 'Baixar orçamento':
        this.quotePDF()
        break;
        case 'Adicionar ao orçamento':
          this.checkout()
        break;
        default:
          return false
        break;
      }
      this.actionSelected = ''
    },
    transferPrice (){
      let price = ''
      switch (this.item.vehicle.type) {
        case 'carro':
          price = this.item.transfer.car_price
          break;
        case 'onibus':
          price = this.item.transfer.bus_price
          break;
        case 'van':
          price = this.item.transfer.van_price
          break;
        case 'micro-onibus':
          price = this.item.transfer.micro_price
          break;
        default:
          break;
      }
      return price
    },
    vehicleDetails (){
      let vehicle = ''
      if(!this.quote){
        vehicle = this.item.vehicle.vehicle_details != null ? this.item.vehicle.vehicle_details.value : ''
      }
      return vehicle
    },
    driverDetails(){
      let driver = ''
      if(!this.quote){
        driver = this.item.driver.driver_details != null ? this.item.driver.driver_details.value : ''
      }
      return driver
    },
    getTransferHour () {
      if (this.item.transferDetails.isChecked === false) {
        return this.item.transferDetails.transfer_time
      } else {
        return this.item.transferDetails.transfer_state === 'destination' ? this.item.transferDetails.transfer_round_time : this.item.transferDetails.transfer_time
      }
    },
    getTransferDate () {
      if (this.item.transferDetails.isChecked === false) {
        return this.item.transferDetails.transfer_date
      } else {
        return this.item.transferDetails.transfer_state === 'destination' ? this.item.transferDetails.transfer_round_date : this.item.transferDetails.transfer_date
      }
    },
    getTransferState () {
      let onlyOrigin = ''
      let state = this.item.transferDetails.transfer_state
      if(state != 'origin' && state != 'destination'){
        onlyOrigin = this.item.transferDetails.transfer_state == 'onlyOrigin' ? 'onlyOrigin' : 'onlyorigin'
      }
      return (state === 'origin') ? 'Ida' : (state === onlyOrigin) ? 'Somente Ida' : 'Volta'
    },
    setImgGalery () {
      if (this.item.vehicle.gallery) {
        this.item.vehicle.gallery.forEach(img => {
          this.gallery.push(img.src)
        })
      }
    },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    },
    downloadContract (item) {
      axios({
        method: 'POST',
        responseType: 'blob',
        url: process.env.API_URL + `services/${item.id}/generate-contract`
      })
      .then((response) => {
        FileSaver.saveAs(response.data, `contrato_servico_${item.id}.pdf`)
      })
    },
    showMultiple (index) {
      this.img = this.gallery

      this.indexImg = index
      this.show()
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    openPassagengerList (item) {
      this.passengers = []
      this.editing_item = item
      this.list_passanger_title = item.vehicle.name
      this.passengers = item.trip.passengers ? item.trip.passengers.value : []

      this.$emit('open-modal', this.passengers)
    },
    dateFormatter: function (date, format) {
      return this.moment(date).format(format)
    },
  },
  mounted () {
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color.$emit,
        '--detail-padding': this.template !== 'list' ? '0 60px' : '0 60px'
      }
    }
  },
  beforeMount () {
    this.setImgGalery()
  }
}
</script>

  <style lang="scss">

  .order-price {
    @media (max-width: 1024px) {
      margin-left: 15px;
    }
  }

  .observations-card {
    p {
      padding: 20px;
      background: #e5e5e5;
      border-radius: 5px;
      color: black;
    }
  }

  .card-order-item {

    background: white;
    
    .col-details {
      padding: 0 15px!important
    }

    .type-tag {
        z-index: 99;
        background: var(--default-color);
        color: white;
        padding: 5px 40px;
        border-radius: 4px;
        width: fit-content;
        font-size: .7rem;
      }

    .trip-type {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;
    }

    .col-image {
      padding-top: 0;
    }

    .vehicle-name {
      font-weight: bold;
    }

    .card-body {
      align-items: center;
    }

    .origin-row {
      margin-bottom: 30px;

      &.address {
        margin-bottom: 0!important;
      }
    }

    .trip-info-title {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    .row-trip-info {
      margin-bottom: 20px;

      &:last-child {
        border: none!important;
      }
    }

    .trip-info {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      svg {
        margin-right: 15px;
      }

      i {
        width: 20px;
        margin-right: 10px;
      }
    }

    .col-price {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0!important;

      @media (max-width: 980px) {
        margin-bottom: 20px!important;
      }

      .price {
        font-size: 22px;
        color: #293c4b;
        font-weight: 700;
        text-align: right;
        @media (max-width: 980px) {
          text-align: center;
        }
      }
    }

    .order-item-actions {
      text-align: right;
      margin-top: 20px;

      @media (max-width: 980px) {
        padding: 0px;
      }

      &.generate-contract {
        border: 1px solid
      }

      .order-item-action {
        border-radius: 5px;
        font-size: 17px;
        font-weight: bold;
        padding: 8px 18px;
        text-decoration: none;
        text-transform: uppercase;

        @media(max-width: 1024px) {
          &.generate-contract {
            margin-top: 10px;
            width: 100%;
            max-width: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            margin-left: 0px!important;
          }
        }

        &.send-list {
          color: #fff;
        }
      }
    }
  }

  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin: 0 20px 5px;
    max-width: 100%;
    width: 100%;

    .col-image {
      padding-right: 10px;
      padding-top: 5px;
      max-width: 70px;
      max-height: 50px;

      img {
        border-radius: 4px;
        max-width: 100%;
      }
    }
  }
</style>
