<template>
  <div id="search-results" :style="cssProps">

    <div id="search-inputs">
      <div class="container">
        <div class="d-flex justify-content-between p-0 re-build-search">
          <div class="inputs">
            <div class="field" :title="origin_title()" v-if="$store.state.simpleTrip.origin.address" v-b-toggle.my-collapse>{{$store.state.simpleTrip.origin.address}}</div>
            <div class="field" :title="destination_title()" v-if="$store.state.simpleTrip.destination.address" v-b-toggle.my-collapse>

              <b-icon-arrow-right v-if="$store.state.serviceType === 'transfer' && !$store.state.transfersRoundTrip.date"/>
              <b-icon-arrow-left-right v-else/>

              {{$store.state.simpleTrip.destination.address}}
            </div>

             <div class="field" title="Detalhes da diária" v-if="this.dailyDistance()" v-b-toggle.my-collapse>
              <img :src="this.busIcon" />
               {{ ` ${quotes[0].quote.daily_hours} horas e ${parseInt(quotes[0].quote.franchise_km)} km` }}
            </div>
            <div class="field" title="Data de ida" v-if="$store.state.simpleTrip.origin.date">
              <div class="field-content" v-b-toggle.my-collapse >
                <b-icon-calendar3 class="calendar"></b-icon-calendar3>
                {{$store.state.simpleTrip.origin.date | date }}
              </div>
            </div>
            <div class="field" title="Data de volta" v-if="$store.state.simpleTrip.destination.date">
              <div class="field-content" v-b-toggle.my-collapse >
                <b-icon-arrow-left-right></b-icon-arrow-left-right>
                <b-icon-calendar3 class="calendar"></b-icon-calendar3>
                {{$store.state.simpleTrip.destination.date | date }}
              </div>
            </div>
          </div>
          <div class="btn do-search d-flex justify-content-center align-items-center pr-0" v-b-toggle.my-collapse @click.prevent="updateRouter()">
            <b-button><b-icon-arrow-clockwise></b-icon-arrow-clockwise> REFAZER BUSCA</b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">

        <div class="col-12">
            <b-collapse id="my-collapse">
                <custom-trip-route :key="randomKey" :customStep="0"></custom-trip-route>
            </b-collapse>

        </div>
        <!-- <div class="col-12 col-lg-4 no-print">
          <search-form context="vertical"></search-form>
        </div> -->
        <div class="col-12">

          <result-list></result-list>

          <!-- <div class="print-budget row no-print">
            <div class="col-12 col-lg-7">
              <p v-if="this.$store.state.companyDetails.quote_valid_days">Se preferir, você pode salvar e imprimir este orçamento para decidir
                depois, mas fique atento, estes valores são válidos até {{this.$store.state.companyDetails.quote_valid_days}}
              </p>
              <p v-else>Se preferir, você pode salvar e imprimir este orçamento para decidir depois</p>
            </div>
            <div class="col-12 col-lg-5" >
              <a class="mt-3 mr-3" @click="$router.go(-1)" :style="{background: secondaryColor, border: secondaryColor}">VOLTAR</a>
              <save-or-print-modal class="mt-3" :show="show" @close="closeModal" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultList from '@/components/search-results/ResultList.vue'
import CustomTripRoute from '../components/modals/CustomTripRoute/CustomTripRoute.vue'

export default {
  components: { ResultList, CustomTripRoute },
  data: function () {
    return {
      busIcon: require('../assets/icon/bus.svg'),
      show: false,
      randomKey: Math.round(Math.random() * 1000),
      mobile: false,
      quotes: this.$store.state.daily.quotes
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color,
        '--default-color-transparent': this.$store.state.companyDetails.whitelabel.body_font_color + 'e6'
      }
    },
    stopPoints () {
      // console.log(this.$store.state.tripDetails)
      return this.$store.state.tripDetails.stop_points
    }
  },
  methods: {
    showModal: function () {
      this.show = true
    },
    closeModal: function () {
      this.show = false
    },
    destination_title () {
      if (this.$store.state.serviceType === 'transfer') {
        return 'Endereço de destino'
      } else {
        return 'Endereço de volta'
      }
    },
    origin_title () {
      if (this.$store.state.serviceType === 'diaria') {
        return 'Origem da diária'
      } else {
        return 'Endereço de ida'
      }
    },
    updateRouter () {
      if(this.$store.state.tripType == 'customtrip') {
        this.$store.commit('SET_CUSTOM_ROUTER_STEP', 0);
        this.randomKey = Math.round(Math.random() * 1000);
      }
    },
    removeDestination () {
      this.$store.state.destination = {
        address: '',
        city: '',
        lat: '',
        date: '',
        lng: '',
        time: ''
      }
      this.$store.state.transfersRoundTrip = {
        address: '',
        date: '',
        observations: '',
        time: ''
      }

      this.$store.commit('SET_TRANSFER_GOING_PROP', { prop: 'isChecked', value: false })
    },
    setType (type) {
      this.$store.commit('SET_TYPE', type)
    },
    destinationDate(){
      let destination = false
      if(this.$store.state.serviceType == 'viagem'){
        destination = this.$store.state.is_round_trip != '' ? true : false
      }else if(this.$store.state.serviceType == 'transfer'){
        destination = this.$store.state.transfersGoing.isChecked ? true : false
      }else {
        destination = false
      }

      return destination
    },
    dailyDistance() {
      let distance = false
      if(this.$store.state.serviceType === 'diaria' && this.quotes.length > 0 && this.$store.state.daily.quotes[0].quote != 0){
        distance = true
      }
      return distance
    },
    originDate(){
      let originDate = (this.$store.state.serviceType == 'viagem') ? this.$store.state.origin.date : (this.$store.state.serviceType == 'transfer') ? this.$store.state.transfersGoing.date : this.$store.state.daily.quotes[0].date

      return originDate
    },
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/components/vehicle-card";
  @import "../assets/scss/pages/_search-results.scss";


  #custom-trip-route {
    margin-bottom: 30px!important;
    padding: 0!important;

  }

  .search-banner {
    height: 360px;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
  }

  .row-trip-info {
    .col-md-12.col-lg-6 {
      .form-row {
        margin-bottom: 15px;
      }
    }
  }

  #my-collapse {
    .form-row.trip-type {
      .trip-type {
        padding: 0;
        margin: 0;
      }
    }
  }

  #search-inputs {
    background-color: var(--default-color);

   
    .re-build-search {

      @media(max-width: 1024px) {
        justify-content: center!important;
      }

      .btn {
        button {
          border-color: var(--default-color);
          color: white;
          background-color: var(--default-color);

          &:hover {
            opacity: .8;
          }
        }
      }

      .inputs {
        margin: 10px 0;
        display: flex;
        position: relative;

        @media(max-width: 1024px) {
          display: none;
        }

        #dailyQuotes {
          position: absolute;
          width: 100%;
          left: -10px;
          top: 40px;
          background: #0558ff;
          padding-left: 10px;
          z-index: 99;
          display: flex;
          padding-top: 5px;
          padding-bottom: 10px;
        }

        .field {
          background: white;
          padding: 5px 20px;
          margin-right: 10px;
          font-size: 12px;
          border-radius: 5px;
          color: black;
          display: flex;
          align-items: center;
          position: relative;

          .close-icon {
              display: none;
          }

          &:hover {
            .close-icon {
              display: flex;
              position: absolute;
              right: 10px;
              top: 5px;

              svg {
                &:hover {
                   background-color: var(--default-color);
                   cursor: pointer;
                   * {
                    fill: white;
                   }
                }
              }
            }
          }

          svg {

            &.bi-arrow-left-right {
                height: 20px!important;
                width: 20px!important;
            }
            
            height: 13px;
            width: 15px;
            &:not(.calendar) {
              background: white;
              padding: 5px;
              font-size: 22px;
              box-shadow: 0 0 3px 1px #bebebe;
              border-radius: 5px;
              position: absolute;
              left: -16px;
            }

            &.calendar {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  #search-results  {

    .container { 
      padding-bottom: 0px!important;
    }
    
    #result-list  {

      @media (max-width: 1024px) {
        .price-wrapper {
          margin-top: 20px;
        }
      }
      .list-header {
        .back {

          @media(max-width: 1024px) {
            margin-bottom: 30px;
          }

          margin-bottom: 50px;
          position: relative;
          border-color: var(--default-color);
          color: var(--default-color);
          background-color: white;
          border-radius: 4px;

          &:hover {
            background-color: var(--default-color);
            color: white;
            opacity: 1
          }
        }
        h2 {
          background-color: var(--default-color-transparent);

          @media(max-width: 1024px) {
            margin-bottom: 30px!important;
            width: 100%;
            min-height: 240px;
          }
        }

        @media(max-width: 1024px) {
          margin-bottom: 0;
          margin-top: 120px;
          width: 100%;
        }
      }

      .row-actions {

        @media (max-width: 980px) {
          padding-left: 20px!important;
          padding-right: 10px!important;

          a {
            margin-bottom: 10px;
          }

          .book-button-wrappper {
            width: 100%;

            .book-button {
              margin-left: 0!important;
            }
          }

          .col-extended-price {
            padding-left: 0;
            display: flex;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 10px;

            .price {

              text-align: right;

              @media (max-width: 980px) {
                text-align: center;
              }

              @media(max-width: 1024px) {
                display: flex;
                flex-direction: column;

                span {
                  margin-left: 0!important;
                }
              }
             
            }
          }
        }

        .col-actions {
          @media (max-width: 1024px) {
            margin: 0;
            padding: 0;
            padding-bottom: 40px;
          }
        }
      }
    }
  }

</style>
