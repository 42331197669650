<template>
    <div class="alert-wrapper"  v-if="responseMessage.status && responseMessage.message">
      <p :class="{ 'alert': true, 'alert-danger': responseMessage.status === 'error' }">{{ responseMessage.message }}</p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Alert',
  data: function () {
    return {
    }
  },
  computed: {
    ...mapGetters(['responseMessage'])
  },
  mounted () {
    this.$store.commit('setResponseMessage', {})
  }
}
</script>
