<template>
  <div class="item-row" :style="cssProps" v-if="item">
    <div class="row item-row-inner justify-content-center align-items-center" style="padding: 0 40px;">
      <div class="col-md-3 item-image-wrapper">
        <img :src="item.vehicle.thumbnail"  class="item-image">
      </div>
      <div class="col-md-9 flex-column col-info">
        <h3 class="title">{{item.vehicle.name}}</h3>
        <div class="configurations-wrapper" v-if="(item.vehicle.configurations.length > 0 || item.vehicle.air_conditioning || item.vehicle.bathroom)">
          <p class="font-weight-bold">Configurações:</p>

          <p class="configurations">

            <span v-if="item.vehicle.air_conditioning">
              Ar condicionado
            </span>
            <span v-if="item.vehicle.bathroom">
              Banheiro
            </span>
            
            <span v-for="(config, index) in item.vehicle.configurations" :key="index">
              {{config}}
            </span>
          </p>
        </div>
      </div>
      <div class="col-md-3">
        <div class="btn btn-block btn-details" v-if="!doSearch && !hideDetailsBtn" @click="showVehicleDetails()">
          <span v-if="!showDetails">Ver Detalhes</span>
          <span v-else>Esconder Detalhes</span>
        </div>
      </div>
    </div>

    <transition name="fade">
    <div class="item-row-inner slide-out" style="padding: 0 40px;">
      <div class="col-md-12 flex-column" v-if="item.type == 'viagem'">
        <trip-address :vehicle="item" :extra="false"/>
        <div class="row">
          <!-- <div class="col-md-6" v-if="item.trip.stopPoints && item.trip.stopPoints.length > 0">
            <h4 class="trip-info-title">Outros pontos de embarque ou desembarque</h4>
            <div v-for="(stop, index) in item.trip.stopPoints" :key="'stop' + index">
              <b-icon-geo-alt></b-icon-geo-alt>
            </div>
          </div>
          <div class="col-md-6" v-if="item.trip.useVehicle && item.trip.useVehicle.length > 0">
            <h4 class="trip-info-title">Uso no destino</h4>
            <div v-for="(use, index) in item.trip.useVehicle" :key="'use' + index" style="width: 100%;">
              <div v-if="use" style="width: 100%;">
                <div class="trip-info">
                  <b-icon-geo-alt></b-icon-geo-alt>
                  {{ use.addr_info.address }}
                </div>
                <div class="trip-info">
                  <b-icon-calendar3></b-icon-calendar3>
                  {{ use.date | date_result_list}}
                </div>
                <div class="trip-info">
                  <b-icon-clock></b-icon-clock>
                  {{ use.time }}
                </div>
                <div class="trip-info">
                  <b-icon-clock></b-icon-clock>
                  {{ use.franchise }}
                </div>
                <hr>
              </div>
            </div>
          </div> -->
          <!-- <div class="col-md-6 pl-0">
            <div class="row-trip-info pl-0">
              <h4 class="trip-info-title">Observações</h4>
              <div class="trip-info">
                {{ item.trip.observations }}
              </div>
            </div>
          </div> -->
            <div class="col-4" v-if="!hideTotal">
              <div class="row flex-column">
                <p class="price-label">Valor Total:</p>
                <p class="item-total">{{ parseFloat(item.total) + parseFloat(item.trip.driverExpenses.driverExpensesValue) | currency }}</p>
              </div>
            </div>

            <div class="col-md-8">

              <div v-if="!hideDetailsBtn">
                <div class="row action-btns d-flex">
                  <div class="btn btn-block btn-details" @click="doSearch">
                    <span>{{searchTitle}}</span>
                  </div>

                  <div class="btn btn-block btn-details btn-delete" @click="removeBudget">
                    <span>Remover pesquisa</span>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
      <div v-else-if="item.type == 'transfer'">
        <div class="row row-trip-info mt-5">
          <div class="col-origin col-md-6">
            <h4 class="trip-info-title">Origem</h4>
            <div class="row">
              <div class="col-md-9">
                <div class="trip-info">
                  <b-icon-geo-alt></b-icon-geo-alt>
                  {{ item.transfer.point_origin.address }}
                </div>

                <div class="trip-info">
                  <b-icon-calendar3></b-icon-calendar3>
                  {{ this.transferDate | date_result_list}}
                </div>
                <div class="trip-info">
                  <b-icon-clock></b-icon-clock>
                  {{ this.transferHour }}
                </div>
              </div>
              <div class="col-md-3">
              </div>
            </div>
          </div>
          <div class="col-destination col-md-6 p-0">
            <h4 class="trip-info-title">Destino</h4>
            <div class="row">
              <div class="col-md-9">
                <div class="trip-info">
                  <b-icon-geo-alt></b-icon-geo-alt>
                  {{ item.transfer.point_destination.address }}
                </div>
              </div>
              <div class="col-md-3">
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row-trip-info">
          <h4 class="trip-info-title">Observações</h4>

          <div class="trip-info">
            <b-icon-clock></b-icon-clock>
            {{ item.transferDetails.observations }}
          </div>
        </div> -->

        <div class="row-totals">
          <p class="item-total">{{ item.total | currency }}</p>
        </div>
      </div>

      <div class="col-lg-9 offset-sm-3 flex-column" v-if="item.type == 'diaria'">
        <!-- <div class="row row-trip-info">
          <div class="col-origin col-12">
            <h4 class="trip-info-title">Detalhes da diaŕia</h4>
            <div class="row">
              <div class="col-md-9">
                <div class="trip-info">
                  <b-icon-geo-alt></b-icon-geo-alt>
                  {{ item.trip.daily.addr_info.address }}
                </div>
                <div class="trip-info">
                  <b-icon-calendar3></b-icon-calendar3>
                  {{ item.trip.daily.date | date_result_list }}
                </div>
                <div class="trip-info">
                  <b-icon-clock></b-icon-clock>
                  {{ item.trip.daily.quote.daily_hours + ' Horas e ' + parseInt(item.trip.daily.quote.franchise_km) + ' Quilômetros' }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="trip-info">
                  <b-icon-clock></b-icon-clock>
                  {{ item.trip.daily.time }}
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="row-trip-info" v-if="item.trip.observations">
          <h4 class="trip-info-title">Observações</h4>

          <div class="trip-info">
            <b-icon-clock></b-icon-clock>
            {{ item.trip.observations }}
          </div>
        </div> -->

        <div class="row-totals">
          <p class="item-total">{{ item.total | currency }}</p>
        </div>
      </div>
    </div>
    </transition>

  </div>
</template>

<script>
import TripAddress from '../search-results/TripDetails/TripAddress.vue';
export default {
    name: "TripItem",
    props: ["item", "index", "showButtonDetail", "doSearch", "searchTitle", "removeBudget", "hideDetailsBtn", "hideTotal"],
    data() {
        return {
            showDetails: false,
            transferDate: this.item.hasOwnProperty("transferDetails") ? this.item.transferDetails.transfer_state == "origin" ? this.item.transferDetails.transfer_date : this.item.transferDetails.transfer_round_date : false,
            transferHour: this.item.hasOwnProperty("transferDetails") ? this.item.transferDetails.transfer_state == "origin" ? this.item.transferDetails.transfer_time : this.item.transferDetails.transfer_round_time : false
        };
    },
    created() {
    },
    methods: {
        showVehicleDetails() {
            this.showDetails = !this.showDetails;
        }
    },
    computed: {
        cssProps() {
            return {
                "--default-color": this.$store.state.companyDetails.whitelabel.body_font_color
            };
        }
    },
    components: { TripAddress }
}
</script>

<style lang="scss">

  .action-btns {
    align-items: flex-end;
    justify-content: center;
    height: 80px;

    .btn-details {
      width: fit-content;
      margin: 0 10px;
    }
  }

  .item-total {
    font-size: 22px;
    margin: 0;
    color: black;
    padding: 0!important;
  }

  .btn-details {
    background-color: var(--default-color)!important;
    border-color: var(--default-color)!important;
    color: white!important;
  }

  .row-actions {
    .col-actions {
      .book-button {
          border-color: var(--default-color);
          color: white!important;
          background-color: var(--default-color);
          border-radius: 4px;
      }
    }
  }

  .first-row {
      padding: 30px 40px;
      @media (max-width: 1024px) {
        padding: 15px;
      }
  }
  .row-trip-info {

    svg {
      margin-right: 15px;
    }

    * {
      color: black;
    }

    margin: 0;

    h4 {
      font-weight: 500!important;
    }

    .trip-info {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  .col-info {
    .configurations-wrapper {
      padding-top: 5px;
      margin-bottom: 20px;

      span {
        background: transparent;
        color: var(--default-color);
        margin-right: 5px;
        padding: 0 5px;
        margin-bottom: 5px;
        display: flex;
        border-color: var(--default-color);
        border: 1px solid;
        border-radius: 5px;
      }
      p {
        text-align: left;
        margin-bottom: 0;
        font-size: 14px;
        color: #000;
        text-transform: capitalize;
        display: flex;
        flex-wrap: wrap;
      }
      .configurations {
        padding-top: 10px;
      }
    }
  }
  .origin-row {
      margin-bottom: 30px;
    }

    .trip-info-title {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    .row-trip-info {
      margin-bottom: 20px;

      &:last-child {
        border: none!important;
      }
    }

    .trip-info {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      i {
        width: 20px;
        margin-right: 10px;
      }
    }
  .configurations {
    span {

    }
  }
  .col-price  {
    .pricePerSeat {
      span {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }
    .more-info  {
      a {
        background-color: var(--default-color);
        border-color: var(--default-color);
        color: white;
      }
    }
  }
  .card{
    padding: 30px 0 !important;
    h3{
      text-align: left;
    }
  }

</style>
