<template>
  <div id="my-account" :style="cssProps">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="page-header my-account-header">
            <my-account-menu />
          </div>
        </div>
        <div class="col-12">
          <div class="actions">

            <profile></profile>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Profile from '@/components/my-account/Profile.vue'
import MyAccountMenu from './MyAccountMenu.vue'

export default {
  name: 'MyAccount',
  metaInfo() {
    return {
      titleTemplate: '%s - Minha conta',
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  components: {
    Profile,
    MyAccountMenu
  },
  created () {

  },
  methods: {
    ...mapActions('auth', ['sendLogoutRequest']),
    logout () {
      this.sendLogoutRequest({ email: this.user.email }).then((response) => {
      })
    },
    setActiveHash (hash) {
      this.$router.push(hash)
    }
  }
}
</script>
<style scoped lang="scss" src="../../assets/scss/pages/_my-account.scss"></style>
<style scoped lang="scss" src="../../assets/scss/pages/_search-results.scss"></style>
<style scoped lang="scss">

  @import "../../assets/scss/components/vehicle-card";

  #my-account {
    background-color: #e8e8e8!important;

    #search-results {
      background: transparent!important;
    }

    .validate-form {
      .card {
        box-shadow: 0px 1px 6px rgba(61,67,76,0.15)!important;
        padding: 20px 15px!important;
        border-radius: 5px;
        background-color: #fff;
      }
    }

    &.empty-list {
        background: white!important;

        .empty-list {
          padding-top: 40px;

          h4 {
            margin-bottom: 30px;
          }

          img {
            max-height: 340px;
          }
        }
    }

    .actions {
      .nav {
       li {
        background: transparent;
       }
      }
    }
  }
    .actions {
      .nav {
        li {
          width: 33.33333%;
          padding-left: 15px;
          padding-right: 15px;
          box-shadow: none!important;
          background-color: white;

          @media (max-width: 576px) {
            width: 100%;
            margin-bottom: 15px;
          }

          a {
            font-size: 22px;
            font-weight: bold;
            border-radius: 4px;
            flex-grow: 1;
            text-align: center;
            padding: 5px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: none;
            background-color: #ffffff;
            color: var(--default-color);
            border: 1px solid ;
            border-color: var(--default-color)!important;

          }
        }
      }

      .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        background: var(--default-color)!important;
        color: #fff;

         &.active {
            background-color: var(--default-color)!important;
            color: #fff;
          }

      }

    }

  .nav {
    justify-content: space-evenly !important;
  }

  .btn-logout {
    background: #D1D1D1 !important;
    a{
      color: #283c4c !important;
    }
  }
</style>
