<template>
  <div v-if="isLoading" class="spinner-wrapper bg-white">
    <lottie-animation
        :width=300
        :height=300
        path="animation/freta-loading.json"
      />
  </div>
  <div v-else class="thank-you" id="thank-you" :style="cssProps">
    <div class="card container-confirmation">
      <h2 class="confirmation-title">Reserva recebida com sucesso!</h2>
      <p class="confirmation-subtitle">Assim que o seu pagamento for confirmado, <br> Enviaremos um e-mail.</p>
    </div>
    <div class="container">

      <div class="col-lg-12 p-0">
        <order-payment-details :order="order" />
      </div>

      
      <div class="col-md-12 p-0">
        <div v-for="(item, index) in order.items" :key="index" >
          <order-trip-item v-if="item.type === 'viagem' || item.type === 'diaria'" @open-modal="openModal" :item="item" :order="order" :index="index"></order-trip-item>
          <order-transfer-item v-else-if="item.type === 'transfer'" @open-modal="openModal" :item="item" :index="index"></order-transfer-item>
        </div>
      </div>

    
      <div class="col-12 p-0">
        <order-summary :order="order" />
      </div>
    
    </div>
    <b-modal id="passenger-list" :style="cssProps" ref="passenger-list" v-bind:title="this.list_passanger_title"  @ok="handleOk">
      <div >
        <h5>Informe os dados do passageiro.</h5>
          <form ref="form" @submit.prevent="addPassenger">
            <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-8 mb-3">
                      <b-form-group
                        label-for="name-input"
                        invalid-feedback="Name is required"
                        :state="nameState">
                        <b-form-input
                          class="form-control"
                          placeholder="Nome"
                          v-model="passenger.name"
                          :state="nameState"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-md-4 mb-3">
                      <b-form-group
                        label-for="name-input"
                        invalid-feedback="Tipo de pessoa é obrigatório">

                        <select required v-model="passenger.isChild" class="form-control">
                          <option value="" selected>É Criança?</option>
                          <option v-for="personOption in personOptions" :key="personOption.key" :value="personOption.key">{{personOption.label}}</option>
                        </select>
                      </b-form-group>
                    </div>

                    <div class="col-md-4 mb-3">
                      <b-form-group
                        label-for="document-input"
                        invalid-feedback="Documento é obrigatório">
                        <b-form-input
                          class="form-control"
                          placeholder="Documento"
                          v-model="passenger.document"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </div>

                    <div class="col-md-4 mb-3">
                      <b-form-group
                        label-for="name-input"
                        invalid-feedback="Tipo de pessoa é obrigatório">

                        <select required v-model="passenger.documentType" class="form-control">
                          <option value="" selected>Tipo de Documento</option>
                          <option v-for="documentType in documentTypes" :key="documentType.key" :value="documentType.key">{{documentType.label}}</option>
                        </select>
                      </b-form-group>
                    </div>

                    <div class="col-md-4 mb-3">
                      <b-form-group
                        label-for="documentIssuer-input"
                        invalid-feedback="Emissor é obrigatório">
                        <b-form-input
                          class="form-control"
                          placeholder="Emissor"
                          v-model="passenger.documentIssuer"
                          required>
                        </b-form-input>
                      </b-form-group>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">
                <button type="submit" class="btn btn-primary">
                    <span>Adicionar passageiro à lista</span>
                    <!-- <font-awesome-icon :icon="['fas','plus']"></font-awesome-icon> -->
                  </button>
              </div>
          </form>
        <div>
          <div class="row">
            <div class="col-9">
              <h5>Lista de passageiros</h5>
            </div>
            <div class="col-3 text-right">
              <a v-if="this.passengers.length > 0" @click.prevent="cleanList">Limpar lista</a>
            </div>
          </div>
          <div>
            <small v-html="this.$store.state.companyDetails.passengers_disclaimer"></small>
          </div>

          <div v-if="this.passengers.length === 0">
            <p class="text-secondary">Você não cadastrou nenhum passageiro ainda</p>
          </div>

          <div v-for="(passenger, index) in passengers" :key="passenger.cpf" class="card p-2">
            <div>Nome: {{passenger.name}}</div>
            <div>Documento: {{passenger.document}} | {{passenger.documentType}} - {{passenger.documentIssuer}}</div>
            <div class="child-tag tag" v-if="passenger.isChild == 'yes'">Criança</div>
            <div class="adult-tag tag" v-if="passenger.isChild == 'no'">Adulto</div>
            <button @click="removePassenger(index)" tabindex="-1" type="button"  style="border:none; background:transparent;color:#999;position: absolute; top:10px; right:12px;">
              <font-awesome-icon title="Remover passageiro da lista" :icon="['fas','times']"></font-awesome-icon>
            </button >
          </div>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel}">
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancelar
        </b-button>

        <b-button size="sm" :title="passengers.length == 0 ? 'Você precisa cadastrar ao menos um passageiro na lista' : false"  :disabled="passengers.length == 0 ? true : false" variant="success" @click="ok()">
          Salvar lista de passageiros
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OrderPaymentDetails from '@/components/general/OrderPaymentDetails.vue'
import OrderSummary from '../components/order/OrderSummary.vue'
import utils from '@/components/mixins/utils'
import axios from 'axios'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import OrderTripItem from '../components/my-account/OrderTripItem.vue'
import OrderTransferItem from '../components/my-account/OrderTransferItem.vue'

export default {
  name: 'OrderReceived',
  components: {
    OrderPaymentDetails,
    OrderSummary,
    LottieAnimation,
    OrderTripItem,
    OrderTransferItem
  },
  mixins: [
    utils
  ],
  title () {
    return `${this.pageTitle}`
  },
  data () {
    return {
      pageTitle: this.$store.state.companyDetails.name + ' - Pedido realizado',
      order: null,
      isLoading: true,
      list_passanger_title: '',
      passenger: {
        id: 0,
        name: '',
        document: '',
        documentType: '',
        documentIssuer: '',
        isChild: ''
      },
      nameState: null,
      passengers: [],
      personOptions: [
        {
          key: 'yes',
          label: 'Sim'
        },
        {
          key: 'no',
          label: 'Não'
        }
      ],
      documentTypes: [
        { key: 'rg', label: 'RG' }, { key: 'cnh', label: 'CNH' }, { key: 'passaporte', label: 'Passaporte' }, { key: 'certidao', label: 'Certidão' }
      ]
    }
  },
  created () {
    /* eslint-disable no-new */
    new Promise((resolve, reject) => {
      axios.get(process.env.API_URL + 'user/orders/' + this.$route.params.slug).then((r) => {
        this.order = r.data.data
        this.isLoading = false
      })
    })
  },
  methods: {
    showAlert (name) {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: 'Espere!',
          text: 'Tem certeza que deseja remover ' + name + ' da lista de passageiros?',
          input: 'checkbox',
          inputValue: this.deleteConfirmation,
          inputPlaceholder:
            'Não pedir confirmação novamente',
          showDenyButton: true,
          confirmButtonText: 'Confirmar',
          denyButtonText: `Cancelar`
        }).then((result) => {
          this.deleteConfirmation = result.value
          resolve(result.isConfirmed)
        })
      })
    },
    cleanList () {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: 'Tem certeza?!',
          text: 'Você está prestes a excluir toda a lista de passageiros',
          showDenyButton: true,
          confirmButtonText: 'Confirmar',
          denyButtonText: `Cancelar`
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.deleteConfirmation = 1
            await this.passengers.forEach((passenger, index) => {
              this.removePassenger(index, true)
            })

            this.passengers = []

            this.$swal('Tudo certo', 'A lista foi excluída!', 'success')
          }
          resolve(result.isConfirmed)
        })
      })
    },

    openModal (passengers) {
      this.loadPassengers()
      this.$refs['passenger-list'].toggle()
    },
    loadPassengers () {
      const orderItemId = this.item.id

      axios({
        method: 'GET',
        url: process.env.API_URL + `services/${orderItemId}/passengers/list`
      })
        .then((response) => {
          this.passengers = response.data.passengers
        })
    },
    addPassenger () {
      if (!this.checkFormValidity()) {
        return
      }

      if (this.passengers) {
        this.passengers.push({
          name: this.passenger.name,
          document: this.passenger.document,
          documentType: this.passenger.documentType,
          documentIssuer: this.passenger.documentIssuer,
          isChild: this.passenger.isChild
        })

        const orderItemId = this.item.id
        axios.post(process.env.API_URL + `services/${orderItemId}/passengers/insert`, { passengers: [{
          id: 0, // Refatorar o backend para inserir um passageiro por vez
          name: this.passenger.name,
          document: this.passenger.document,
          documentType: this.passenger.documentType,
          documentIssuer: this.passenger.documentIssuer,
          isChild: this.passenger.isChild,
          order_item_id: orderItemId
        }] })
          .then((response) => {
            // this.passengers = response.data.passengers
            this.success = true
            this.passenger.document = ''
            this.passenger.name = ''
            this.passenger.documentType = ''
            this.passenger.documentIssuer = ''
            this.passenger.isChild = ''
          })
      }
    },
    async removePassenger (index, cleanAll = false) {
      let passenger = this.passengers[index]
      try {
        let shouldIRemoveThisPassenger = this.deleteConfirmation === 0 ? await this.showAlert(passenger.name) : true
        if (shouldIRemoveThisPassenger) {
          if (passenger !== undefined && passenger.id !== 0) {
            const orderItemId = this.item.id

            axios.post(process.env.API_URL + `services/${orderItemId}/passenger/${passenger.id}/delete`)
              .then((response) => {
                // this.passengers = response.data.passengers
                if (!cleanAll) {
                  this.success = true
                  this.passengers.splice(index, 1)

                  this.$swal('Tudo certo', 'Passageiro excluído com sucesso!', 'success')
                }
              })
          }
        }
      } catch (error) {
      }
    },
    loadPassengers () {
      const orderItemId = this.item.id

      axios({
        method: 'GET',
        url: process.env.API_URL + `services/${orderItemId}/passengers/list`
      })
        .then((response) => {
          this.passengers = response.data.passengers
        })
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk (bvModalEvt) {
      const orderItemId = this.item.id
      axios.post(process.env.API_URL + `services/${orderItemId}/passengers/insert`, { passengers: this.passengers })
        .then((response) => {
          this.passengers = response.data.passengers
          this.success = true
        })
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.body_font_color
    }
  }
}
</script>

<style lang="scss">
  #passenger-list .modal-content .modal-header {
      background: transparent;
      margin: 0;
  }
  #passenger-list.modal.fade.show {
    height: 100vh;
    align-items: center;
    justify-content: center!important;
    display: flex!important;
    padding-left: 0!important;
  }

  #passenger-list.modal.fade.show .modal-dialog {
    width: 90vw !important;
    max-width: 1290px!important;
    position: relative;
    margin: 0 auto;
    height: auto;
    padding-top: 0;
    background-color: white!important;
    overflow: auto;
    border-radius: 10px;
    max-height: 85vh;
  }

  #passenger-list.modal.fade.show .modal-dialog label {
    font-size: 12px;
    margin-bottom: 0;
  }


  #passenger-list.modal.fade.show .modal-content .col-md-3, #passenger-list.modal.fade.show .modal-content .col-md-2,  #passenger-list.modal.fade.show .modal-content .col-md-1:not(:last-of-type) {
    padding-right: 0;
  }

  #passenger-list.modal.fade.show .modal-content .form-control {
    border: 1px solid #d1d1d1!important;
    height: auto;
  }

    #passenger-list.modal.fade.show .modal-content {
      min-height: 100%;
      background: white !important;
      height: auto;
      overflow: auto;
    }

    #passenger-list .btn-primary {
      width: 100%;
      margin: 0 15px;
      background: white;
      color: #143381;
      margin-bottom: 20px;
    }

    #passenger-list .btn-success:disabled {
      background: #bebebe;
      border-color: #bebebe;
      cursor: not-allowed;
    }

    #passenger-list .btn-danger {
      background: white;
      color: #e63e36;
      padding: 10px 20px;
    }

    #passenger-list .btn-success {
      color: white;
      background: #143381;
      border-color: #143381;
      padding: 10px 20px;
    }

    #passenger-list footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      background: white;
      margin: 0;
      z-index: 9999;
    }

    #passenger-list .tag {
      border-radius: 100px;

      width: fit-content;
      padding: 1px 20px;
      color: white;
      font-size: 12px;
    }

    #passenger-list .child-tag {
      background-color: #3e6ee7;
    }

    #passenger-list .adult-tag {
      background-color: #1b2f62;
    }
  .order-details {
    h4 {
      &.order-items {
        padding: 20px 40px;
        background: white;
        border-radius: 5px;
        margin-bottom: -10px;
        width: fit-content;
      }
    }
  }

  .card-body {
    padding: 0!important;
  }
  .payment-order-details {
    padding: 30px!important;
    h3 {
      margin-bottom: 20px;
    }

    h4 {
      margin-top: 10px;
      font-size: 16px;
      color: var(--default-color);
      font-family: Poppins,sans-serif;
    }
    .order-payment-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      border-bottom: 1px dashed #d9d9d9;
      color: #373737;
      font-size: 16px;

      &.totals {
        font-size: 18px;
      }

      .value.total {
        font-weight: 600
      }
    }

  }

  #thank-you {
    min-height: 762px;
    background-color: #e8e8e8;

    svg {
      margin-right: 15px;
    }
  }

  #nav {
    margin-bottom: 0 !important;
  }


  .card-order-details {
    padding: 80px;
    border-radius: 0px 0px 15px 15px!important;
  }

  .container-confirmation {
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0px 0px 80px;
    color: white;
    background: var(--default-color)!important;
    border-radius: 0!important;

    h2 {
      font-size: 42px;
      color: white;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      font-size: 24px;
      color: white;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .item-order {
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
  }

  .card {
    filter: drop-shadow(0px 8px 9px rgba(0,0,0,0.18));
    background-color: #ffffff;
    border-radius: 8px;

    &.card-page-header {
      margin-top: 60px;
    }
  }

  .container-order-payment {
    margin-top: 15px;
  }

  @media(max-width: 767px) {
    .container-confirmation {
      padding: 20px 15px;
      margin: 30px 0px 40px;

      h2 {
        font-size: 22px;
        margin-bottom: 15px;
        text-align: center;
      }

      p {
        font-size: 20px;
      }
    }

    #thank-you {
      .container-title {
        text-align: center;
        margin-bottom: 25px;
      }
    }

    .item-order {
      padding: 20px 15px;
    }
  }

</style>
