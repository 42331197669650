<template >
  <div class="py-2">
    <div class="alert alert-warning pass-alert-message" role="alert" v-if="isLeadCustomer">
      Você está acessando uma conta temporária, por favor defina uma nova senha para completar seu cadastro.
    </div>
    <div class="container p-0 my-account-container">
        <form class="login100-form validate-form" v-on:submit.prevent="update" :style="cssProps">
          <div class="d-flex flex-md-row flex-column">
            <div class="col-md-4 pl-0 card-information col-12">
              <div class="card">
                <h3>Conta</h3>
                <div :class="{'form-group':true, 'has-feedback': errors.name}">
                  <label for="name">Nome</label>
                  <div
                      class="wrap-input100 validate-input "
                      data-validate="Nome é obrigatório">
                      <input
                      class="form-control input100 form-control"
                      type="text"
                      name="name"
                      v-model="user.name"

                      />
                  </div>
                  <div class="invalid-feedback " v-if="errors.name">
                      {{ errors.name[0] }}
                  </div>
                </div>
                <div :class="{'form-group':true, 'has-feedback': errors.email}">
                  <label for="email">E-mail</label>
                  <div
                      class="wrap-input100 validate-input"
                      data-validate="E-mail é obrigatório"
                  >
                      <input
                      class="form-control input100"
                      type="email"
                      name="email"
                      disabled
                      v-model="user.email"

                      />
                  </div>
                  <div class="invalid-feedback " v-if="errors.email">
                    {{ errors.email[0] }}
                  </div>
                </div>
                <div :class="{'form-group':true, 'has-feedback': errors.password}">
                  <label for="pass">Senha <span class="pass-alert text-danger" v-if="isLeadCustomer">(Necessário definir uma nova)</span></label>
                  <div class="wrap-input100 validate-input" data-validate="Senha é obrigatório">
                    <input
                      class="form-control input100"
                      type="password"
                      id="userpass"
                      v-model="user.password"
                      name="pass"

                        />
                  </div>
                  <div class="invalid-feedback " v-if="errors.name">
                    {{ errors.name[0] }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-8 pr-0 card-information col-12">
              <div class="card">
             <h3>Informações da conta</h3>
            <div :class="{'form-group':true, 'has-feedback': errors.name}">
            <label for="name">Nome</label>
            <div
                class="wrap-input100 validate-input"
                data-validate="Nome é obrigatório"
            >
                <input
                class="form-control input100"
                type="name"
                v-model="user.name"
                name="name"

                />
            </div>
            <div class="invalid-feedback " v-if="errors.last_name">
                {{ errors.last_name[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.last_name}">
            <label for="last_name">Sobrenome</label>
            <div
                class="wrap-input100 validate-input"
                data-validate="Sobrenome é obrigatório"
            >
                <input
                class="form-control input100"
                type="last_name"
                v-model="user.last_name"
                name="last_name"

                />
            </div>
            <div class="invalid-feedback " v-if="errors.number_address">
                {{ errors.number_address[0] }}
            </div>
          </div>
          
          <div
            :class="{
              'form-group mb-4': true,
              'has-feedback': errors.cnpj_cpf,
            }"
            v-if="this.user.type_people == '1' || !this.user.type_people "
          >
            <label for="">CPF</label>
            <the-mask mask="###.###.###-##" class="form-control input100" v-model="user.cnpj_cpf" placeholder="000.000.000-00"/>
          </div>

          <div
            :class="{
              'form-group mb-4': true,
              'has-feedback': errors.cnpj_cpf,
            }"
            v-if="this.user.type_people == '2' "
          >
            <label for="">CNPJ</label>
            <the-mask mask="##.###.###/####-##" class="form-control input100" v-model="user.cnpj_cpf" placeholder="00.000.000/0000-00"/>
          </div>


          <div :class="{'form-group':true}">
            <label for="tel">Telefone</label>
            <div
                class="wrap-input100 validate-input"
                data-validate="Telefone é obrigatório"
            >
            <the-mask :mask="['(##) ####-####', '(##) #####-####']"
                            class="input-card form-control input-form"
                            v-model="user.tel"
                            />
            </div>
            <div class="invalid-feedback " v-if="errors.state">
                {{ errors.state[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.state}">
            <label for="state">Estado</label>
            <div class="wrap-input100 validate-input" data-validate="Estado é obrigatório">
              <select name="state" v-model="user.state"  class="form-control input100" >
                <option v-for="(uf, value) in getUfs()" :value="uf" :key="value">{{value}}</option>
              </select>
            </div>
            <div class="invalid-feedback " v-if="errors.cep">
                {{ errors.cep[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.city}">
            <label for="cep">Cidade</label>
            <div
                class="wrap-input100 validate-input"
                data-validate="Cidade é obrigatório"
            >
                <select name="state" v-model="user.city"  class="form-control input100" >
                  <option v-for="(city, idx) in cities" :value="city" :key="idx">{{city}}</option>
              </select>
            </div>
            <div class="invalid-feedback " v-if="errors.cep">
                {{ errors.cep[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.neighborhood}">
            <label for="neighborhood">Bairro</label>
            <div
                class="wrap-input100 validate-input"
                data-validate="Bairro é obrigatório"
            >
                <input
                class="form-control input100"
                type="neighborhood"
                v-model="user.neighborhood"
                name="neighborhood"

                />
            </div>
            <div class="invalid-feedback " v-if="errors.tel">
                {{ errors.tel[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.number_address}">
            <label for="number_address">Número</label>
            <div
                class="wrap-input100 validate-input"
                data-validate="Número é obrigatório"
            >
                <input
                class="form-control input100"
                type="number_address"
                v-model="user.number_address"
                name="number_address"

                />
            </div>
            <div class="invalid-feedback " v-if="errors.tel">
                {{ errors.tel[0] }}
            </div>
          </div>
          <div :class="{'form-group':true, 'has-feedback': errors.cep}">
            <label for="cep">CEP</label>
            <div class="wrap-input100 validate-input" data-validate="CEP é obrigatório">
              <the-mask mask="#####-###" class="form-control input100" v-model="user.cep"  />
            </div>
            <div class="invalid-feedback " v-if="errors.cep">
                {{ errors.cep[0] }}
            </div>
          </div>
              <button class="btn btn-primary update-profile">Atualizar</button>
          </div>

          </div>
          </div>
        </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '@/components/mixins/utils'
import axios from 'axios'

export default {
  name: 'Profile',
  mixins: [
    utils
  ],
  data (){
    return {
      cc_errors: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters(['errors']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    isLeadCustomer () {
      return this.$store.state.auth.userData.type_people === 'lead'
    }
  },
  watch: {
    'user.state': function (val) {
      this.cities = this.searchCityByUf(val)
    }
  },
  mounted () {
    this.$store.commit('setErrors', {})
  },
  created () {
    this.cities = this.searchCityByUf(this.user.state)   
  },
  data: function () {
    return {
      cities: []
    }
  },
  methods: {
    ...mapActions('auth', ['sendRegisterRequest']),
    update () {
      this.cc_errors = {};
      if(this.$store.state.auth.userData.cnpj_cpf === ''){
          this.$swal({
            text: 'CPF é obrigatório',
            showDenyButton: false,
            showConfirmButton: true,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
          return false
      }
      if(this.$store.state.auth.userData.tel === ''){
          this.$swal({
            text: 'Telefone é obrigatório',
            showDenyButton: false,
            showConfirmButton: true,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'error'
          })
          return false
      }
      this.resetPassword()
      axios.post(process.env.API_URL + 'user/update', this.user).then((response) => {
        this.$store.commit('setUserData', response.data)
        if(response.status === 200) {
          this.$swal({
            title: 'Sucesso!',
            timer: 2500,
            text: 'Dados atualizados com sucesso!',
            showDenyButton: false,
            showConfirmButton: false,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            icon: 'success'
          })

          store.commit('auth/setUserData', response.data.data)
        }
        
      })
    },
    resetPassword(){
      let userData = {
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.password,
      }
      if(this.user.password != ''){
        axios.post(process.env.API_URL + 'password/restore-myaccount', userData).then((response) => {
          return response
        })
      }
    }

  }
}
</script>

<style lang="scss">

.pass-alert  {
  font-size: 12px;
}
.pass-alert-message {
  position: sticky;
  top: 0;
  z-index: 99;
}

@media (max-width: 1024px) {
  .card-information {
    padding:0;
  }

  .my-account-container, .card-information {
    padding: 0!important;
  }

}

#nav {
  margin-bottom: 0 !important;
}

#app {
  text-align: left;
}

.update-profile  {
  color: white;
  background-color: var(--default-color)!important;
  border-color: var(--default-color)!important;

  &:hover {
    opacity: .7;
  }
}

.card {
  background: #fff;
  padding: 15px;
  border: none;

  h3 {
    margin-bottom: 30px;
    text-align: center;
  }
}
</style>
