<template>
  <div>
    <div class="col-12 my-account-menu d-xl-flex d-none" :style="cssProps">
      <router-link to="/minha-conta/reservas" :class="{'btn' : true, 'active' : this.$route.name === 'reservas'}">Minhas reservas</router-link>
      <router-link to="/minha-conta/orcamentos" :class="{'btn' : true, 'active' : this.$route.name === 'orcamentos'}">Meus orçamentos</router-link>
      <router-link to="/minha-conta" :class="{'btn' : true, 'active' : this.$route.name === 'minha-conta'}">Meus dados</router-link>
    </div>
    <div class="w-100 mb-3 mt-5 d-xl-none d-flex" :style="cssProps" >
      <select class="w-100 form-control" name="my-account-menu" id="" @change="mobileChangePage($event)">
        <option class="btn" value="/minha-conta/reservas" :selected="currentRouteName == 'reservas'">Minhas reservas</option>
        <option class="btn" value="/minha-conta/orcamentos" :selected="currentRouteName == 'orcamentos'">Meus orçamentos</option>
        <option class="btn" value="/minha-conta" :selected="currentRouteName == 'minha-conta'">Meus dados</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MyAccountMenu',
  computed: {
    ...mapGetters('auth', ['user']),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    currentRouteName() {
        return this.$route.name;
    }
  },
  created () {

  },
  methods: {
    ...mapActions('auth', ['sendLogoutRequest']),
    mobileChangePage(route){
      this.$router.push(route.target.value)
    }
  }
}
</script>

<style lang="scss">
  .my-account-menu {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 40px 0;

    .btn {
      flex: 1;
      background-color: white;
      color: var(--default-color);
      border: 1px solid var(--default-color);

      a {
        color: var(--default-color);
      }

      &:hover {
          color: var(--default-color);
          opacity: .6;
        a {
          color: var(--default-color);
        }
      }
      &.active {
        background-color: var(--default-color);
        color: white;
      }
    }
  }
</style>
