<template>
  <div v-if="isLoading" class="spinner-wrapper bg-white">
    <lottie-animation
        :width=300
        :height=300
        path="animation/freta-loading.json"
      />
  </div>
  <div v-else-if="orders.length == 0" class="d-flex justify-content-center flex-column mb-5 text-center">
    <div class="empty-list">
      <img class="skyline-1" src="../../assets/img/empty-list.svg" alt="">
      <h4 class="mt-5">Você não fez nenhum pedido ainda!</h4>
    </div>
  </div>
  <div v-else class="orders pt-2" id="search-results">
    <div id="result-list" :class="cssProps">
      <div class="order" v-for="(order) in orders" :key="order.id">
        <div class="order-inner">
          <div class="col-trip-info col-lg-12">
            
            <div class="row mt-2 row-order-details w-100 p-0 m-0">
              <div class="row w-100 m-0 mb-2 flex-xl-row d-xl-flex flex-column row-margin">
                <div class="col-9 p-0">
                  <h4 class="info-row">
                    <span class="order-number">Pedido: #{{order.id}}</span>
                    <span class="date">{{ order.orderDate | date_result_list_wtz }}</span>
                  </h4>
                </div>
                <!-- <div class="col-6">
                  <span class="order-total" :style="{color: 'black'}"><span class="created_at">{{order.created_at | date_result_list_wtz}}</span>
                  <span class="separator"> | </span>
                  <span class="total">{{order.total | currency}}</span>
                  </span>
                </div> -->

                <div class="status-wrapper col-3 justify-content-end text-right p-0"><div class="badge" :style="{ backgroundColor: order.orderStatusColor }">{{ order.orderStatus }}</div></div>
              </div>

              <div class="col-md-12 col-reservations p-0">

                <div v-for="(item, index) in order.items" :key="index" >
              <order-trip-item v-if="item.type === 'viagem' || item.type === 'diaria'" template="list" :order="order" :item="item" :index="index"></order-trip-item>
              <order-transfer-item v-else-if="item.type === 'transfer'" template="list" :item="item" :index="index"></order-transfer-item>
            </div>
              </div>

              <div class="col-12 d-flex flex-xl-row flex-column align-items-center col-action ">
                <div class="col-xl-9 col-12 p-0">
                  <h5>Total:</h5>
                 <span class="order-total">
                  {{order.total | currency}}
                 </span>
                </div>
                <div class="col-xl-3 col-12 p-0">
                  <router-link :to="orderLink(order)" class="btn p-0 w-100 btn-fretatech px-4 p-2">Ver Detalhes  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import OrderTripItem from './OrderTripItem.vue'
import OrderTransferItem from './OrderTransferItem.vue'

export default {
  name: 'Orders',
  data: function () {
    return {
      isLoading: true,
      orders: [],
      editing_order: null,
      passenger: {
        name: '',
        cpf: ''
      },
      nameState: null
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  components: { LottieAnimation, OrderTripItem, OrderTransferItem },
  methods: {
    orderLink (order) {
      return {
        name: 'order',
        params: {
          slug: order.id
        }
      }
    },
    getOrders () {
      axios.get(process.env.API_URL + 'user/orders').then((response) => {
        this.orders = response.data.data.filter(order => order.hasOwnProperty('items'))
        this.isLoading = false

        if(this.orders.length == 0){
          document.getElementById('my-account').className += " empty-list";
        }
      })
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    }
  },
  mounted () {
    this.getOrders()
  }
}
</script>

<style scoped lang="scss">

  .row-margin {
    margin: 0 15px!important;
  }
  .card-order-item {
    padding: 30px 15px !important;
  }

  .order-total {
    font-size: 20px;
  }

  .page-header {
    h1 {
      text-align: center;
    }
  }
  .payment-status {
    text-align: center;
  }

  .row-order-details {
    align-items: center;

    @media (max-width: 1024px) {
      .badge {
        margin-top: 10px;
      }
    }
  }

  .reservation-item {
    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    .detail-title {
      font-weight: bold;
    }
  }

  .btn-fretatech {
    background-color: var(--default-color);
    color: white;
    &:hover {
      opacity: .8;
      color: white;
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 690px !important;
      margin: 1.75rem auto;
    }
  }

  .orders {
    .vehicle-image {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    .col-image,
    .col-status,
    .col-send-receipt,
    .col-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      padding-top: 30px;
      padding-bottom: 20px;


    }

    .col-trip-info {
      p,
      span,
      h4 {
        color: #333;
      }

      p {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .info-row {
        text-align: left;
        color: #000;
        margin-bottom: 10px;
        flex-direction: column;
        display: flex;

        .date {
          margin-left: 10px;
          font-size: 16px;
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      table {
        .additional {
          th,
          td {
            color: #67acbc;
          }
        }

        th {
          text-align: left;
        }

        td {
          text-align: right;
        }
      }
    }

    .col-send-receipt  {
      a {
        text-decoration: none;
      }

      text-align: center;

      span {
        display: block;
        color: #000;
        font-size: 18px;
        line-height: 1;
        margin-bottom: 15px;
      }

      svg {
        font-size: 36px;
        color: #67acbc;
      }
    }

    .col-status {
      .label {
        color: #000;
        font-size: 18px;
      }

      span {
        font-size: 14px;
        display: block;
      }
    }

    .col-actions {
      .btn-send-list {
        background-color: #4ca92b;
        color: #fff;
        border-radius: 100px;
        margin-bottom: 20px;
        font-size: 14px;
      }

      .details {
        text-transform: uppercase;
      }
    }

    .order {
      margin: 15px;
      box-shadow: 0 0 15px 3px rgba(0,0,0,0.1);
      padding: 20px 0;
      border-radius: 5px;
      background-color: #fff;

      @media (max-width: 1024px) {
        margin: 0;
        margin-bottom: 20px;
      }
      .order-inner {
        display: flex;
        flex-wrap: wrap;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .col-action {
    position: sticky;
    bottom: 0;
    background: white;
    padding-top: 15px!important;
  }

  .underline {
    height: 1px;
    bottom: -4px;
    margin: 2rem 0;
    left: 0;
    width: 70%;
    background: #E5E5E5;
    -webkit-transition: .5s;
    transition: .5s;
  }

  .skyline-1 {
    height: 280px;
    object-fit: contain;
  }

  .reservation-wrapper {
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 10px;
  }

  .reservation-wrapper:last-child {
    border-bottom: none;
  }

  @media(max-width: 767px) {
    .orders {
      .order-total {
        color: black;

        .created_at,
        .separator {
          display: none;
        }
      }

      .col-reservations {
        margin-top: 30px;
        margin-bottom: 30px;

        h4 {
          margin-bottom: 15px;
        }
      }

      .col-action {
        justify-content: center;
      }
    }
  }
</style>
