<template>
  <div :class="{ 'result-item result-expanded mb-3': true, 'expanded': true}" :style="cssProps" :id="'vehicle-'+item.selectedVehicle.id">
    <div class="first-row row m-0 w-100">
      <div class="col-12 d-flex flex-row align-items-center">
        <div class="col-image">
          <img  :src="item.selectedVehicle.image || item.selectedVehicle.thumbnail" class="vehicle-image">
        </div>
        <div class="col-info">
          <h3 class="title">{{ item.selectedVehicle.title || item.selectedVehicle.name }}</h3>
          <div class="configurations-wrapper" v-if="(item.selectedVehicle.configurations.length > 0 || item.selectedVehicle.air_conditioning || item.selectedVehicle.bathroom)">
            <p class="font-weight-bold">Configurações:</p>
            <p class="configurations">
              <span v-if="item.selectedVehicle.air_conditioning">
                Ar condicionado
              </span>
              <span v-if="item.selectedVehicle.bathroom">
                Banheiro
              </span>
              
              <span v-for="(config, index) in item.selectedVehicle.configurations" :key="index">
                {{config}}
              </span>
            </p>
          </div>
        </div>

        <div class="col-price text-right">
          <div class="price w-100">
            <span class="value">{{ item.transfer[this.getVehicleType(item.selectedVehicle.type) + '_price'] | currency }}</span>
          </div>

          <button @click.prevent="removeCartItem" class="btn btn-block remove mt-2">REMOVER ITEM</button>
        </div>
        
      </div>
    </div>

    <div class="row row-trip-info w-100">
      <div class="col-12 mt-4">
        <h4 class="trip-info-title">Transfer {{this.transferState(item)}}</h4>
        <div class="usage-item align-items-start row mb-4 w-100">

          <div class="col-origin col-md-5 m-0 pr-5">
            <h4 class="trip-info-title">Origem</h4>
            <div class="trip-info">
              <b-icon-geo-alt></b-icon-geo-alt>
              {{ item.transfer.point_origin.address  }}
            </div>
            <div class="trip-info">
              <b-icon-calendar3></b-icon-calendar3>
              {{ this.transferDate | date_result_list }}
            </div>
            <div class="trip-info">
              <b-icon-clock></b-icon-clock>
              {{ this.transferHour}}
            </div>
          </div>

          <div class="col-destination col-md-5 p-0">
            <h4 class="trip-info-title">Destino</h4>
            <div class="trip-info">
              <b-icon-geo-alt></b-icon-geo-alt>
              {{ item.transfer.point_destination.address }}
            </div>
          </div>

          <div class="col-2 align-items-end">
            <div class="price-wrapper">
              <div class="price">
                <span class="value">{{ item.total | currency}}</span>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    
  </div>

</template>

<script>

export default {
  name: 'DailyItem',
  props: ['item', 'index'],
  data () {
    return {
      isVisible: false,
      visible: false,
      gallery: [],
      img: '',
      indexImg: 0,
      transferDate: this.transferDateFormated(),
      transferHour: this.transferTime(),
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
    secondaryColor () {
      return this.$store.state.companyDetails.whitelabel.secondaryColor
    }
  },
  mounted () {
    this.setImgGalery()
  },
  methods: {
    transferState (item) {
      let state = item.selectedVehicle.extra.transfer_state
      return (state === 'origin') ? 'Ida' : (state === 'onlyOrigin') ? 'Somente Ida' : 'Volta'
    },
    removeCartItem () {
      this.$store.commit('cart/removeItem', this.index)
      this.$store.commit("cart/calculateCartTotal", {data: this.$store.state});
    },
    showVehicleDetails () {
      this.isVisible = true
    },
    hideVehicleDetails () {
      this.isVisible = false
    },
    transferDateFormated(){
      let date = this.item.selectedVehicle.extra.transfer_state == 'origin' || this.item.selectedVehicle.extra.transfer_state == 'onlyOrigin' ? this.item.transferDetails.transfer_date : this.item.transferDetails.transfer_round_date

      return date
    },
    transferTime(){
      let hour = this.item.selectedVehicle.extra.transfer_state == 'origin' || this.item.selectedVehicle.extra.transfer_state == 'onlyOrigin' ? this.item.transferDetails.transfer_time : this.item.transferDetails.transfer_round_time

      return hour
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    },
    showMultiple (index) {
      this.img = this.gallery

      this.indexImg = index
      this.show()
    },
    getVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'bus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro'
        case 'carro' :
          return 'car'
        default:
          return 'bus'
      }
    },
    setImgGalery () {
      this.item.selectedVehicle.gallery.forEach(img => {
        this.gallery.push(img.src)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .observations-card {
    p {
      padding: 20px;
      background: #e5e5e5;
      border-radius: 5px;
      color: black;
    }
  }

  .trip-info{
    text-transform: capitalize;
  }

  .trip-item-card {
    border: none!important;
    background-color: #ffffff;

    .checkout-table {
      padding-left: 15px!important;
      border-top: 1px solid #e5e5e5;

      .row {
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
        color: black;
      }

    }

    hr {
      margin: 0!important;

      &:last-child {
        display: none!important;
      }
    }

    .card-info {
      padding: 30px 15px 0;

      @media (max-width: 980px) {
        padding: 20px 20px 0;
      }

      .type-tag {
          position: absolute;
          left: 0;
          z-index: 99;
          background: var(--default-color);
          color: white;
          padding: 5px 40px;
          top: 20px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
      }

      .remove {
        background: transparent;
        border-radius: 4px;
        border-color: #8f8f8f;
        color: #8f8f8f;
        font-weight: 400;

        &:hover {
          border-color: var(--default-color);
          color: var(--default-color);
        }
      }
    }

    .row-actions {
      .col-actions {
        .book-button {
            border-color: var(--default-color);
            color: white!important;
            background-color: var(--default-color);
            border-radius: 4px;
        }
      }
    }

    .first-row {
      padding: 30px 40px;
    }

    .row-stop-points {
      .col-md-6 {
       
      }
    }
    .row-trip-info {
      background: white;
      margin: 0;
      padding: 30px 15px;

      &:last-child {
        border: none!important;
      }

      .trip-info-title {
        font-weight: 500 !important;
        font-size: 16px!important;
        margin-bottom: 20px!important;
      }
      .trip-info {
          font-size: 16px;
          margin-bottom: 10px;
           color: black!important;

          * {
            color: black!important;
          }

          svg {
              margin-right: 15px!important;
          }
      }

      h4 {
        font-weight: 500!important;
      }
    }
    .col-info {

      .title {
        .type {
            color: #0558ff;
            font-size: 18px;
            width: 100%;
            display: block;
            opacity: .7;
        }
      }
      .configurations-wrapper {
        padding-top: 5px;

        span {
          background: transparent;
          color: #0d5bdc;
          margin-right: 5px;
          padding: 0 5px;
          margin-bottom: 5px;
          display: flex;
          border-color: #0d5bdc;
          border: 1px solid;
          border-radius: 5px;
        }
        p {
          text-align: left;
          margin-bottom: 0;
          font-size: 14px;
          color: #000;
          text-transform: capitalize;
          display: flex;
          flex-wrap: wrap;
        }
        .configurations {
          padding-top: 10px;
        }
      }
    }
    .configurations {
      span {

      }
    }
    .col-price  {
      .pricePerSeat {
        span {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
        }
      }
      .more-info  {
        a {
          background-color: var(--default-color);
          border-color: var(--default-color);
          color: white;
        }
      }
    }

    .item-image-wrapper {
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      justify-content: center;
    }

    .item-title {
      margin-bottom: 20px;
    }

    .price {
      font-size: 22px;
      color: #293c4b;
      font-weight: bold;
      text-align: right;
      @media (max-width: 980px) {
        text-align: center;
      }
    }

    .card-body {
      padding: 0;
      box-shadow: 0px 1px 6px rgba(61,67,76,0.15);
    }

    .item-image {
      max-width: 100%;
      border-radius: 8px;
      margin-bottom: 15px;
    }
  }
</style>
